import { Github } from 'react-bootstrap-icons'
import { ProjectCredits, ProjectLink, ProjectLinks, ProjectTitle, Showreel } from '../components'

const Miniman = (props) => {
  const images = [
    {
      thumbnail: '/images/miniman/snapshot-1-thumb.jpg',
      original: '/images/miniman/snapshot-1-600x375.jpg',
      fullscreen: '/images/miniman/snapshot-1-1280x800.jpg',
    },
    {
      thumbnail: '/images/miniman/snapshot-2-thumb.jpg',
      original: '/images/miniman/snapshot-2-600x375.jpg',
      fullscreen: '/images/miniman/snapshot-2-1280x800.jpg',
    },
    {
      thumbnail: '/images/miniman/snapshot-3-thumb.jpg',
      original: '/images/miniman/snapshot-3-600x375.jpg',
      fullscreen: '/images/miniman/snapshot-3-1280x800.jpg',
    },
    {
      thumbnail: '/images/miniman/snapshot-4-thumb.jpg',
      original: '/images/miniman/snapshot-4-600x375.jpg',
      fullscreen: '/images/miniman/snapshot-4-1280x800.jpg',
    },
    {
      thumbnail: '/images/miniman/snapshot-5-thumb.jpg',
      original: '/images/miniman/snapshot-5-600x375.jpg',
      fullscreen: '/images/miniman/snapshot-5-1280x800.jpg',
    },
  ]

  return (
    <article className={props.swap ? "swap" : undefined}>
      <Showreel
        images={images}
        video={{
          id: '3wV7lb52jzk',
          thumbnail: '/images/miniman/snapshot-video-thumb.jpg'
        }} />

      <div className="grid-body">
        <ProjectTitle
          name="Miniman"
          year="2014"
          tech="SFML"
          techLink="http://www.sfml-dev.org/" />

        <p>
          Miniman is a straightforward, shameless, Pac-Man clone. After <em>The Lone Cabin</em>,
          I wanted to work on my C++ skills and SFML looked like a great lightweight library for a game project.
        </p>
        <p>
          Since this was going to be a pure technical exercise project, I didn't want to spend time designing game mechanics or
          creating complicated assets. The
          {' '}
          <a href="http://www.gamasutra.com/view/feature/3938/the_pacman_dossier.php" target="_blank" rel="noreferrer">Pac-Man Dossier</a>
          {' '}
          proved extremely helpful as it described everything I needed to know about Pac-Man, its mechanics, and AI.
        </p>
        <p>
          The Pac-Man-<i>ish</i> sound effects were created with a handy tool named
          {' '}
          <a href="http://www.superflashbros.net/as3sfxr/" target="_blank" rel="noreferrer">as3sfxr</a>.
        </p>

        <ProjectCredits
          person="Antti Hellsten"
          domain="Graphics"
          note="iconic character and wall sprites" />

        <ProjectLinks>
          <ProjectLink
            href="https://github.com/pekkahe/miniman"
            targetBlank
            tooltip="View source at GitHub">
            <Github />
            <span>GitHub</span>
          </ProjectLink>
        </ProjectLinks>
      </div>
    </article>
  )
}

export default Miniman
