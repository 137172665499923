import { Container, Jumbotron } from 'react-bootstrap'
import { InboxFill, Github, Linkedin } from 'react-bootstrap-icons'
import './App.css'
import './projects/Projects.css'
import Navigation from './Navigation'
import * as projects from './projects'

const App = () => {
  return (
    <div className="app">
      <Jumbotron className="app-header">
        <div className="app-header-bg min-width" />
        <Container>
          <h1>Pekka Hellsten</h1>
          <p>Software developer and gaming enthusiast.</p>
        </Container>
      </Jumbotron>

      <Navigation />

      <Container className="app-main">
        <section className="app-about grid">
          <article>
            <div>
              <p>
                Hello, I'm a software developer living in Helsinki, Finland. On this site you will find a
                selection of my personal software projects.
              </p>
              <p>
                In my career I've built tools and services for empowering users within the high power electrical
                equipment and video game industries. Being an avid gamer lead me to pursue a degree in software
                engineering, graduate into the software industry, and eventually transition into
                the game industry.
              </p>
              <p>
                Games are engaging and their technologies are becoming more and more fascinating, but it's
                really the art of programming and software engineering that I'm in love with; designing and
                implementing clean, robust solutions to manage software complexity.
              </p>
            </div>
            <ul>
              <li>
                <a href="https://www.linkedin.com/in/pekkahellsten/"><Linkedin />LinkedIn</a>
              </li>
              <li>
                <a href="https://github.com/pekkahe"><Github />GitHub</a>
              </li>
            </ul>
          </article>
        </section>
        <section className="app-projects grid">
          <projects.RenderingEngine />
          <projects.BitmapCompressor swap />
          <projects.GridJumper />
          <projects.Miniman swap />
          <projects.AsteroidDefense />
          <projects.TheLoneCabin swap />
          <projects.BricksAndBlocks />
        </section>
      </Container>

      <Container className="app-footer">
        Copyright {new Date().getFullYear()} © Pekka Hellsten
      </Container>
    </div>
  )
}

export default App
