import { Navbar } from 'react-bootstrap'
import './Navigation.css'

const Navigation = () => {
  return (
    <Navbar className="app-nav" expand="sm">
      {/*
      <Container>
        <Navbar.Brand onClick={scrollToTop}>Pekka Hellsten</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#projects" activeClassName="active">Projects</Nav.Link>
            <Nav.Link href="#about" activeClassName="active">About me</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      */}
    </Navbar>
  )
}

export default Navigation
