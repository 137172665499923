import React from 'react'

const ProjectLinks = (props) => {
  const renderVersion = () => {
    if (props.version) {
      return <div className="version-info">v{props.version}</div>
    } else {
      return <></>
    }
  }

  return (
    <div className="project-links">
      <div className="links">
        {props.children}
      </div>
      {renderVersion()}
    </div>
  )
}

export default ProjectLinks

