import React from 'react'

const ProjectLink = (props) => {
  return (
    <a href={props.href}
      target={props.targetBlank ? "_blank" : "_self"}
      rel={props.targetBlank ? "noreferrer" : undefined}
      title={props.tooltip}>
      {props.children}
    </a>
  )
}

export default ProjectLink
