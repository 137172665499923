import { Image } from 'react-bootstrap'
import { Github } from 'react-bootstrap-icons'
import { ProjectLink, ProjectLinks, ProjectTitle } from '../components'

const BitmapCompressor = (props) => {
  return (
    <article className={props.swap ? "swap" : undefined}>
      <aside className="showreel">
        <Image src="images/snapshot-csharp-600x375.jpg" width="500" />
      </aside>

      <div className="grid-body">
        <ProjectTitle
          name="Bitmap Compressor"
          year="2017"
          tech="C#"
          techLink="https://msdn.microsoft.com/en-us/library/kx37x362.aspx" />

        <p>
          Bitmap Compressor is a Windows console application that allows you to compress bitmap textures into
          {' '}
          <a href="https://msdn.microsoft.com/en-us/library/bb694531(v=VS.85).aspx" target="_blank" rel="noreferrer">block compressed</a>
          {' '}
          <a href="https://msdn.microsoft.com/en-us/library/bb943990(v=VS.85).aspx" target="_blank" rel="noreferrer">DDS</a> files and vice versa.
          It supports the BC1, BC2, and BC3 compression formats.
        </p>
        <p>
          This project originally started as a small exercise for BC1 compression, but I picked it up again a couple of years
          after when I was looking for a programming project. I decided to redesign and refactor the application to make it
          easy to add new block compression formats, and focused on achieving a high test coverage.
        </p>

        <ProjectLinks>
          <ProjectLink
            href="https://github.com/pekkahe/bitmap-compressor"
            targetBlank
            tooltip="View source at GitHub">
            <Github />
            <span>GitHub</span>
          </ProjectLink>
        </ProjectLinks>
      </div>
    </article>
  )
}

export default BitmapCompressor
