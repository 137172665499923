import { Download } from 'react-bootstrap-icons'
import { ProjectLink, ProjectLinks, ProjectTitle, Showreel } from '../components'

const BricksAndBlocks = (props) => {
  const images = [
    {
      thumbnail: '/images/bricksandblocks/snapshot-1-thumb.jpg',
      original: '/images/bricksandblocks/snapshot-1-600x375.jpg',
      fullscreen: '/images/bricksandblocks/snapshot-1-1280x800.jpg',
    },
    {
      thumbnail: '/images/bricksandblocks/snapshot-2-thumb.jpg',
      original: '/images/bricksandblocks/snapshot-2-600x375.jpg',
      fullscreen: '/images/bricksandblocks/snapshot-2-1280x800.jpg',
    },
    {
      thumbnail: '/images/bricksandblocks/snapshot-3-thumb.jpg',
      original: '/images/bricksandblocks/snapshot-3-600x375.jpg',
      fullscreen: '/images/bricksandblocks/snapshot-3-1280x800.jpg',
    },
    {
      thumbnail: '/images/bricksandblocks/snapshot-4-thumb.jpg',
      original: '/images/bricksandblocks/snapshot-4-600x375.jpg',
      fullscreen: '/images/bricksandblocks/snapshot-4-1280x800.jpg',
    },
    {
      thumbnail: '/images/bricksandblocks/snapshot-5-thumb.jpg',
      original: '/images/bricksandblocks/snapshot-5-600x375.jpg',
      fullscreen: '/images/bricksandblocks/snapshot-5-1280x800.jpg',
    },
  ]

  return (
    <article className={props.swap ? "swap" : undefined}>
      <Showreel
        images={images}
        video={{
          id: 'S4Buv3NFUaY',
          thumbnail: '/images/bricksandblocks/snapshot-video-thumb.jpg'
        }} />

      <div className="grid-body">
        <ProjectTitle
          name="Bricks And Blocks"
          year="2012"
          tech="XNA"
          techLink="http://en.wikipedia.org/wiki/Microsoft_XNA" />

        <p>
          Bricks And Blocks is a simple Tetris clone I made with XNA as my first game project. There's a high score
          system, three difficulty levels to choose from, and a weird gladiator theme. I was a huge fan of the TV
          series <a href="https://en.wikipedia.org/wiki/Spartacus_(TV_series)" target="_blank" rel="noreferrer">Spartacus</a> at the time.
        </p>
        <p>
          Now that XNA has been discontinued by Microsoft, this will remain as my last work with the framework. I still
          have the book <i>Learning XNA 4.0</i> gathering dust on my bookshelf.
        </p>

        <ProjectLinks>
          <ProjectLink
            href="/builds/bricks-and-blocks.rar"
            tooltip="Download ClickOnce archive">
            <Download />
            <span>Download</span>
          </ProjectLink>
        </ProjectLinks>
      </div>
    </article>
  )
}

export default BricksAndBlocks
