import { Github } from 'react-bootstrap-icons'
import { ProjectLink, ProjectLinks, ProjectTitle, Showreel } from '../components'

const AsteroidDefense = (props) => {
  const images = [
    {
      thumbnail: '/images/asteroiddefense/snapshot-1-thumb.jpg',
      original: '/images/asteroiddefense/snapshot-1-600x375.jpg',
      fullscreen: '/images/asteroiddefense/snapshot-1-1280x800.jpg',
    },
    {
      thumbnail: '/images/asteroiddefense/snapshot-2-thumb.jpg',
      original: '/images/asteroiddefense/snapshot-2-600x375.jpg',
      fullscreen: '/images/asteroiddefense/snapshot-2-1280x800.jpg',
    },
    {
      thumbnail: '/images/asteroiddefense/snapshot-3-thumb.jpg',
      original: '/images/asteroiddefense/snapshot-3-600x375.jpg',
      fullscreen: '/images/asteroiddefense/snapshot-3-1280x800.jpg',
    },
    {
      thumbnail: '/images/asteroiddefense/snapshot-4-thumb.jpg',
      original: '/images/asteroiddefense/snapshot-4-600x375.jpg',
      fullscreen: '/images/asteroiddefense/snapshot-4-1280x800.jpg',
    },
    {
      thumbnail: '/images/asteroiddefense/snapshot-5-thumb.jpg',
      original: '/images/asteroiddefense/snapshot-5-600x375.jpg',
      fullscreen: '/images/asteroiddefense/snapshot-5-1280x800.jpg',
    },
  ]

  return (
    <article className={props.swap ? "swap" : undefined}>
      <Showreel
        images={images}
        video={{
          id: 'QhMHG-tU74c',
          thumbnail: '/images/asteroiddefense/snapshot-video-thumb.jpg'
        }} />

      <div className="grid-body">
        <ProjectTitle
          name="Asteroid Defense"
          year="2014"
          tech="Unity"
          techLink="http://unity3d.com/" />

        <p>
          Asteroid Defense is a small exercise project where the goal was to simulate an asteroid defense system.
          It fires torpedoes towards asteroids whose trajectories come within a given safety distance to the space station.
        </p>
        <p>
          Asteroids are spawned periodically within the simulation, but the player can also spawn and launch them
          towards the station. All torpedoes travel at a fixed speed and direction, so in order to intercept an asteroid
          the torpedo's launch direction is calculated based on the asteroid's position and velocity.
        </p>

        <ProjectLinks>
          <ProjectLink
            href="https://github.com/pekkahe/asteroid-defense"
            targetBlank
            tooltip="View source at GitHub">
            <Github />
            <span>GitHub</span>
          </ProjectLink>
        </ProjectLinks>
      </div>
    </article>
  )
}

export default AsteroidDefense
