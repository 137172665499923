import { Image } from 'react-bootstrap'
import { Github } from 'react-bootstrap-icons'
import { ProjectLink, ProjectLinks, ProjectTitle } from '../components'

const RenderingEngine = (props) => {
  return (
    <article className={props.swap ? "swap" : undefined}>
      <aside className="showreel">
        <Image src="images/engine/snapshot-1-500x400.jpg" />
      </aside>

      <div className="grid-body">
        <ProjectTitle
          name="Rendering Engine"
          year="2019"
          tech="C++"
          techLink="https://en.wikipedia.org/wiki/C%2B%2B" />

        <p>
          The goal of this project was to build a 3D renderer with C++ and
          {' '}
          <a href="https://www.opengl.org/about" target="_blank" rel="noreferrer">OpenGL</a>
          {' '}
          and use it as a platform for online tools, such as a simple scene or object editor,
          using <a href="https://emscripten.org/" target="_blank" rel="noreferrer">Emscripten</a> and modern web technologies.
        </p>
        <p>
          Having developed a game editor at work using traditional desktop application technologies, I wanted to make a similar
          (yet much more basic) set of tools with web technologies instead. Performance degradation considered, the convenience
          of being able to access your engine tools using a browser is compelling.
        </p>
        <p>
          But alas, the project pivoted quite early and I switched my focus towards the
          {' '}
          <a href="https://en.wikipedia.org/wiki/Entity_component_system" target="_blank" rel="noreferrer">Entity Component System</a>
          {' '}
          architecture. ECS was something we were investigating at work and I loved its clear separation of data and behavior,
          which scales extremely well across multiple threads. Sadly, I lost momentum and have not touched this project for a while.
        </p>

        <ProjectLinks>
          <ProjectLink
            href="https://github.com/pekkahe/anon-engine"
            targetBlank
            tooltip="View source at GitHub">
            <Github />
            <span>GitHub</span>
          </ProjectLink>
        </ProjectLinks>
      </div>
    </article>
  )
}

export default RenderingEngine
