import { useEffect, useState } from 'react'
import ImageGallery from 'react-image-gallery'
import YouTube from 'react-youtube'

const Showreel = ({ images, video }) => {
  const [items, setItems] = useState([])
  const [player, setPlayer] = useState()
  const [fullscreenButton, setFullscreenButton] = useState(video === undefined)

  const onSlide = (currentIndex) => {
    if (player) {
      if (player.getPlayerState() === YouTube.PlayerState.PLAYING) {
        player.pauseVideo()
      }
      setFullscreenButton(currentIndex !== 0)
    }
  }

  useEffect(() => {
    const renderVideo = (id) => {
      const opts = {
        width: '512',
        height: '314',
        playerVars: {
          origin: 'https://pekkahellsten.com',
        },
      }

      const onReady = (event) => {
        setPlayer(event.target)
      }

      return (
        <div className="showreel-video">
          <YouTube
            videoId={id}
            id={id}
            opts={opts}
            onReady={onReady} />
        </div>
      )
    }

    if (video) {
      let items = images.slice()
      items.unshift({
        thumbnail: video.thumbnail,
        original: video.thumbnail,
        renderItem: () => renderVideo(video.id),
      })

      setItems(items)
    } else {
      setItems(images)
    }
  }, [images, video])

  return (
    <aside className="showreel">
      <ImageGallery
        items={items}
        showPlayButton={false}
        showFullscreenButton={fullscreenButton}
        onSlide={onSlide} />
    </aside>
  )
}

export default Showreel
